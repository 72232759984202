@import url(https://fonts.googleapis.com/css?family=Oxygen&display=swap);
html,
body {
  background-color: white;
  height: 100%;
  font-family: Oxygen, 'Helvetica Neue', sans-serif;
}

#root {
  min-height: 100%;
}

footer {
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  background-color: #000105;
  color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}

.person-card .card-title {
  font-weight: 700;
}

.person-card .title {
  font-weight: 300;
  margin: 0;
}

.person-card .sub-title {
  font-weight: 200;
  margin: 0;
  font-size: 13pt;
}

#root,
#root > div,
.home {
  height: 100%;
}

.cover-5,
.cover-5 .cover-image,
.cover-5 .video-fadein {
  background-color: #000000 !important;
}

.cover-5 .video-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.cover-5 .video-container {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: #000105;
}

.video-container video {
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
  opacity: 0.4;
}

.cover-5 {
  background: #000000;
}

.fullscreen-cover {
  width: 100%;
  height: 100%;
  background-color: #000105;
  overflow: hidden;
  /* opacity: 0.2; */
  position: relative;
}

.middle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  text-align: center;
  width: auto;
}

.main-title-text {
  font-size: 30px;
  color: #ffffff;
}

.cover-5 .cover-headline h1 {
  text-transform: none !important;
}

.color-and-height {
  color: #ffffff;
  text-align: center;
}

.h1-text-size {
  font-size: 72px !important;
  line-height: 87px !important;
  color: white !important;
  opacity: 1;
}

@media (max-width: 1199px) and (min-width: 980px) {
  .h1-text-size {
    font-size: 48px !important;
    line-height: 55.3846153846px !important;
  }
}

@media (max-width: 979px) and (min-width: 768px) {
  .h1-text-size {
    font-size: 42.3529411765px !important;
    line-height: 51.4285714286px !important;
  }
}

@media (max-width: 767px) {
  .h1-text-size {
    font-size: 27.6923076923px !important;
    line-height: 32.7272727273px !important;
  }
}

@media (max-width: 567px) {
  .h1-text-size {
    font-size: 21.1764705882px !important;
    line-height: 24px !important;
  }
}

.main-opacity-text {
  opacity: 1;
  left: 2.5%;
  right: 2.5%;
}

.cover-5 {
  background-color: #ffffff;
  background-size: cover;
  background-position: center center;
  z-index: 1;
}

#index-video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -100;
  right: 0;
  bottom: 0;
  background-size: cover;
  overflow: hidden;
}

.home-section {
  padding: 20px;
}

.home-section h1 {
  font-size: 52pt;
}

